import styled from "styled-components";
import logoFullBlue from "../../assets/logo-full-blue.png";
import theme from "../../styles/theme";

export const Container = styled.header`
  background-color: ${theme.colors.white};
  position: fixed;
  top: 0;
  width: 100vw;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  margin-bottom: 30px;
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.div`
  width: 180px;
  height: 100px;
  background-image: url(${logoFullBlue});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: -40px;
`;

export const ContainerMenu = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const Options = styled.a`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 50px;
  text-decoration: none;
  @media (max-width: 700px) {
    margin-right: 10px;
  }
`;

export const about = styled.div`
  margin-top: 100vh;
  padding: 20px;
  background-color: #f0f0f0;
`;
