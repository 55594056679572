/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container, ContentArea, Logo, ContentInfo } from "./styles";
import { Text } from "../../components/Text";
import theme from "../../styles/theme";

function Footer() {
  return (
    <>
      <Container>
        <ContentArea>
          <Logo />
          <ContentInfo>
            <Text
              size={theme.sizes.rem1}
              size_m={theme.sizes.rem1}
              color={theme.colors.white}
            >
              (34) 99976-3566
            </Text>
            <Text
              size={theme.sizes.rem1}
              size_m={theme.sizes.rem1}
              color={theme.colors.white}
            >
              contato@neway.com.br
            </Text>
            <Text
              size={theme.sizes.rem0_75}
              size_m={theme.sizes.rem1}
              color={theme.colors.white}
              style={{
                marginTop: "10px",
              }}
            >
              Copyright @ 2024 | Neway | Todos os direitos reservados
            </Text>
          </ContentInfo>
        </ContentArea>
      </Container>
    </>
  );
}

export default Footer;
