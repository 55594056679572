/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Text } from "../../components/Text";
import { Container, Center, Content, Button } from "./styles";
import About from "../About";
import Contact from "../Contact";
import theme from "../../styles/theme";

function Home() {
  const aboutRef = useRef(null);

  return (
    <>
      <Header />
      <Container id={"home"}>
        <Content>
          <Center>
            <Text
              size={theme.sizes.rem2}
              size_m={theme.sizes.rem2}
              color={theme.colors.white}
              align={"center"}
            >
              Soluções avançadas para sua empresa
            </Text>

            <Text
              size={theme.sizes.rem1_5}
              size_m={theme.sizes.rem1}
              color={theme.colors.white}
              align={"center"}
              style={{ lineHeight: "30px" }}
            >
              A Neway é uma empresa de tecnologia especializada em desenvolver
              soluções de TI avançadas e plataformas de serviços para
              seguradoras, distribuidores e consumidores. Com a Neway você
              amplia o alcance e a eficiência da sua ideia, estabelecendo um
              futuro mais inovador.
            </Text>
          </Center>
        </Content>
      </Container>
      <About />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
