import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  background-color: ${theme.colors.beige};
  width: 100vw;
  height: 100vh;

  @media (max-width: 768px) {
    height: 100vh;
  }
`;
