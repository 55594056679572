import styled from "styled-components";
import logoFullBlue from "../../assets/logo-white.png";
import theme from "../../styles/theme";

export const Container = styled.header`
  background-color: ${theme.colors.lightBlue};
  width: 100vw;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentArea = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Mobile */
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 65px;

  /* Mobile */
  @media (max-width: 700px) {
    width: 50%;
    margin-right: 6px;
  }
`;

export const Logo = styled.div`
  width: 180px;
  height: 100px;
  background-image: url(${logoFullBlue});
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -30px;

  /* Mobile */
  @media (max-width: 700px) {
    margin-left: 0px;
    width: 50%;
    height: 100px;
  }
`;
