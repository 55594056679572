import styled from "styled-components";
import theme from "../../styles/theme";

export const Text = styled.h1`
  margin: 0px;
  padding: 0px;
  /* background-color: transparent; */
  font-family: "Labora", sans-serif;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: ${({ size }) => (size ? size : theme.sizes.rem2)}rem;
  text-align: ${({ align }) => align || "left"};
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  color: ${({ color }) => color || theme.colors.black};
  text-transform: ${({ transform }) => transform || "none"};
  text-decoration: ${({ decoration }) => decoration || "none"};
  cursor: ${({ cursor }) => (cursor ? cursor : "normal")};
  transition: font-size 0.8s ease;

  /* Tablet and Smaller Desktop */
  @media (min-width: 701px) and (max-width: 1120px) {
    font-size: ${({ size_m }) => (size_m ? size_m : theme.sizes.rem1_5)}rem;
  }
  /* Mobile */
  @media (max-width: 700px) {
    font-size: ${({ size_m }) => (size_m ? size_m : theme.sizes.rem1_5)}rem;
  }
`;

export const TextHeading = styled.h1`
  font-size: 3rem;
  font-family: "Inter";
  @media (min-width: 1024px) {
    font-size: 3.75rem;
    letter-spacing: -0.05em;
  }
  @media (min-width: 1280px) {
    font-size: 4.5rem;
    letter-spacing: -0.025em;
  }
  margin-top: 5%;
  font-weight: bold;
  color: #131312;
`;
