import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    height: 70vh;
  }
`;

export const FirstStep = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  align-items: flex-end;
  justify-content: center;
  @media (max-width: 700px) {
    margin-top: 40px;
    height: 10%;
    align-self: center;
  }
`;

export const SecondStep = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  width: 90%;
  height: 70%;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    height: 80%;
    margin-bottom: 40px;
  }
`;

export const SquareLogo = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 25%;
  height: 50px;
  align-items: center;
  justify-content: center;
  object-fit: contain;

  @media (max-width: 700px) {
    width: 40%;
    height: 30px;
  }
`;
