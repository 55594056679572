/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Text } from "../../components/Text";
import { Container, FirstStep, SecondStep, SquareLogo } from "./styles";
import logoOceanica from "../../assets/logo-oceanica.png";
import logoSeven from "../../assets/logo-seven.png";
import logoMypass from "../../assets/logo-mypass.png";
import logoAx from "../../assets/logo-ax.png";
import logoMovim from "../../assets/logo-movim.png";
import logoSemperder from "../../assets/logo-semperder.png";
import logoTorre from "../../assets/logo-torre.png";
import logoFortt from "../../assets/logo-fortt.png";
import logoSplit from "../../assets/logo-split.png";

function Contact() {
  return (
    <Container id={"contact"}>
      <FirstStep>
        <Text style={{ alignItems: "center", textAlign: "center" }}>
          Alguns clientes que já utilizam soluções da Neway
        </Text>
      </FirstStep>
      <SecondStep>
        <SquareLogo src={`${logoOceanica}`} />
        <SquareLogo src={`${logoSeven}`} />
        <SquareLogo src={`${logoMypass}`} />
        <SquareLogo src={`${logoMovim}`} />
        <SquareLogo src={`${logoSemperder}`} />
        <SquareLogo src={`${logoAx}`} />
        <SquareLogo src={`${logoFortt}`} />
        <SquareLogo src={`${logoSplit}`} />
      </SecondStep>
    </Container>
  );
}

export default Contact;
