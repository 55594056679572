/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container, ContentArea, Logo, ContainerMenu, Options } from "./styles";
import { hover } from "@testing-library/user-event/dist/hover";

function Header() {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Container>
        <ContentArea>
          <Logo onClick={() => scrollToSection("home")} />
          <ContainerMenu>
            <Options onClick={() => scrollToSection("home")}> Sobre </Options>
            <Options onClick={() => scrollToSection("about")}>
              Portfólio
            </Options>
            <Options onClick={() => scrollToSection("contact")}>
              Parceiros
            </Options>
          </ContainerMenu>
        </ContentArea>
      </Container>
    </>
  );
}

export default Header;
