/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container } from "./styles";
import Carousel from "../../components/Carousel";

function About() {
  return (
    <Container id={"about"}>
      <Carousel />
    </Container>
  );
}

export default About;
