import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CenteredDots,
  Container,
  CenteredDotsWaze,
  CenteredDotsPlat,
} from "./styles";
import { Text } from "../../components/Text";
import dashboardImage from "../../assets/dashboard.png";
import theme from "../../styles/theme";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

function Carousel() {
  return (
    <Container>
      <Slider {...settings}>
        <CenteredDots>
          <Text bold={true} align={"center"}>
            Waze dos negócios
          </Text>
          <Text align={"center"} size={theme.sizes.rem1}>
            dashboard Estratégico
          </Text>

          <Text
            align={"center"}
            size={theme.sizes.rem1}
            size_m={theme.sizes.rem1_25}
            style={{ marginTop: "20px" }}
          >
            O "Waze dos Negócios" é uma solução de dashboard interativo e
            intuitivo, projetado especificamente para atender às necessidades
            dos MGAs da seguradora SplitRisk. Este poderoso instrumento de
            análise de dados consolida informações críticas para a tomada de
            decisão, otimizando a gestão e o monitoramento de performances chave
            no setor de seguros.
          </Text>
          <img
            src={dashboardImage}
            alt="Descrição da imagem"
            style={{
              marginTop: "20px",
              maxWidth: "10%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </CenteredDots>
        <CenteredDotsWaze>
          <Text bold={true} align={"center"}>
            Chatbot automatizado
          </Text>
          <Text align={"center"} size={theme.sizes.rem1}>
            para a jornada do cliente
          </Text>

          <Text
            align={"center"}
            size={theme.sizes.rem1}
            size_m={theme.sizes.rem1_25}
            style={{ marginTop: "20px" }}
          >
            Inteligência artificial projetada para transformar a interação
            digital entre MGAs e seus clientes. Integrando-se perfeitamente com
            plataformas de mensagens para oferecer uma gama completa de serviços
            automatizados que abrangem toda a jornada do cliente, desde a
            cotação até o pós-venda.
          </Text>
          <img
            src={dashboardImage}
            alt="Descrição da imagem"
            style={{
              marginTop: "20px",
              maxWidth: "10%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </CenteredDotsWaze>
        <CenteredDotsPlat>
          <Text bold={true} align={"center"}>
            Plataforma digital
          </Text>
          <Text align={"center"} size={theme.sizes.rem1}>
            relacionamento com o cliente
          </Text>

          <Text
            align={"center"}
            size={theme.sizes.rem1}
            size_m={theme.sizes.rem1_25}
            style={{ marginTop: "20px" }}
          >
            A Plataforma Digital de Relacionamento com Clientes é uma solução
            avançada desenvolvida especificamente para otimizar o serviço de
            atendimento ao cliente dos MGAs da seguradora Split Risk. Utilizando
            tecnologias modernas de comunicação como chat e WhatsApp, esta
            plataforma integra funcionalidades sofisticadas para gestão,
            monitoramento e execução de um atendimento eficiente e altamente
            responsivo.
          </Text>
          <img
            src={dashboardImage}
            alt="Descrição da imagem"
            style={{
              maxWidth: "10%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </CenteredDotsPlat>
      </Slider>
    </Container>
  );
}

export default Carousel;
