import styled from "styled-components";
import theme from "../../styles/theme";

export const Container = styled.div`
  width: 100vw;
  height: 80vh;

  @media (max-width: 700px) {
    height: 70%;
    /* margin-right: 6px; */
  }
`;

export const CenteredDots = styled.div`
  padding: 40px;

  margin-top: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 30px;

  /* Mobile */
  @media (max-width: 700px) {
    margin-top: 70px;
  }
`;

export const CenteredDotsWaze = styled.div`
  margin-top: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  padding: 40px;

  /* Mobile */
  @media (max-width: 700px) {
    margin-top: 70px;
  }
`;

export const CenteredDotsPlat = styled.div`
  margin-top: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  padding: 40px;

  /* Mobile */
  @media (max-width: 700px) {
    margin-top: 70px;
  }
`;
