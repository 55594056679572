import styled from "styled-components";
import neway from "../../assets/neway.png";
import blue from "../../assets/blue.jpg";

export const Container = styled.div`
  display: flex;
  background-image: url(${neway});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    background-image: url(${blue});
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  align-self: center;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin-top: 120px;
  @media (max-width: 700px) {
    margin-top: 250px;
    gap: 50px;
  }
`;
